import React from "react"
import styled from "styled-components"

export const StyledStep = styled.div`
  max-width: 360px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  padding-bottom: 4px;
  text-align: center;
  & .title {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.3px;
    color: #062964;
  }

  & .desc {
    margin-top: 16px;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.3px;
    color: #3a465b;
  }
  @media (min-width: 1024px) {
    margin-bottom: 0;
    padding-bottom: 0;
    & .title {
      font-size: 22px;
    }
    & .desc {
      font-size: 15px;
    }
  }
  @media (min-width: 1200px) {
    & .desc {
      margin-top: 22px;
      font-size: 16px;
    }
    & .title {
      font-size: 24px;
    }
  }
`

const StyledImg = styled.img`
  width: 160px;
  margin-bottom: 16px;
  @media (min-width: 1024px) {
    margin-bottom: 30px;
  }
  @media (min-width: 1200px) {
    width: 230px;
  }
`

const Step = ({ img, title, desc }) => {
  return (
    <StyledStep>
      <StyledImg src={img} alt={title} />
      <p className="title">{title}</p>

      <p className="desc">{desc}</p>
    </StyledStep>
  )
}

export default Step
