import React from "react"
import styled from "styled-components"
import Step, { StyledStep } from "./Step"
import linebtm from "../images/ui/stepsLineBottom.svg"
import linetop from "../images/ui/stepsLineTop.svg"
import auto from "../images/steps/auto.svg"
import holowanie from "../images/steps/holowanie.svg"
import naprawa from "../images/steps/naprawa.svg"

const StyledSection = styled.section`
  padding: ${props =>
    props.lastSection ? "70px 16px 64px" : "70px 16px 32px"};
  background-color: transparent;
  @media (min-width: 1024px) {
    padding: ${props =>
      props.lastSection ? "105px 16px 0" : "105px 16px 60px"};
    margin-top: 0;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 44px;
  justify-content: center;
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
    & ${StyledStep} {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 300px;
        height: 30px;
        z-index: -5;
      }
      &:nth-child(odd) {
        &::after {
          background-image: url(${linebtm});
          right: -50%;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &:nth-child(even) {
        &::after {
          background-image: url(${linetop});
          right: -50%;
          top: 10%;
          transform: translateY(100%);
        }
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    grid-column-gap: 62px;
  }
`

const Steps = ({ lastSection }) => {
  return (
    <StyledSection lastSection={lastSection}>
      <Container>
        <Step
          title="Holowanie z OC sprawcy"
          desc="  Lorem ipsum, dolor sit amet consectetur adipisicing."
          img={holowanie}
        />
        <Step
          title="Darmowe auto zastępcze"
          desc="  Lorem ipsum, dolor sit amet consectetur adipisicing."
          img={auto}
        />
        <Step
          title="Bezgotówkowa naprawa auta"
          desc="  Lorem ipsum, dolor sit amet consectetur adipisicing."
          img={naprawa}
        />
      </Container>
    </StyledSection>
  )
}

export default Steps
